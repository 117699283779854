var site = site || {};
var prodcat = prodcat || {};
var generic = generic || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  Drupal.behaviors.productInventoryStatusV1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $templates = $('.product-inventory-status-v1', context);

      $templates.each(function () {
        var $template = $(this);
        var $product = $template.closest('.js-product-ui');
        var $notify = $product.find('.js-product-notify');

        $notify.once().on('click', function (e) {
          e.preventDefault();
          var skuBaseId = $product.attr('data-sku-base-id');
          var skuInvStatus = $product.attr('data-sku-inv-status');
          var TEMP_OOS = 2;
          var SOLD_OUT = 7;
          var invStatus = _.contains([TEMP_OOS, SOLD_OUT], parseInt(skuInvStatus)) ? 'BIS' : 'CS';
          var file =
            '/waitlist/signup.tmpl?SKU_BASE_ID=' + skuBaseId + '&REQUEST_TYPE=' + invStatus;
          var options = {
            includeBackground: false,
            includeCloseLink: true,
            hideOnClickOutside: true,
            cssStyle: {
              border: '1px solid #5dba98',
              width: '500px',
              height: '250px',
              padding: '0'
            }
          };

          options.content =
            '<iframe  class="waitlist-iframe-wrapper" frameborder="0" src="' + file + '"></iframe>';
          generic.overlay.launch(options);
        });
      });
    },
    attached: false
  };

  $(document).on('product.updateInvStatus', '.js-product-ui:not(.js-product-bundle-ui)', function () {
    var $product = $(this);
    var $addBtn = $('.js-product-add-to-bag', $product);
    var $addBtnContainer = $addBtn.parent();
    var $liveChatContainer = $('.product-full__live-chat-container', $product);
    var $statusList = $('.js-product-inventory-status-list', $product);
    var skuBaseId = $product.data('sku-base-id');
    var skuDataL2 = prodcat.data.getSku(skuBaseId);
    var invStatus = skuDataL2 !== null ? skuDataL2.INVENTORY_STATUS : '';
    var $newStatus = $('.product-inventory-status--' + invStatus, $statusList);
    var isMobile = typeof site.client.isMobile == 'undefined' ? '0' : site.client.isMobile;
    var $notifyMeButton = $('.js-product-full-notify-me, .js-product-brief-notify-me', $product);
    var $invStatusNotifyMe = $('.js-notify_me_inventory_status--' + invStatus, $product);
    var isCr21 = Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr21_spp;

    // Update data attrubutes
    if (skuDataL2) {
      $statusList.hide();
      $notifyMeButton.addClass('hidden');
      $notifyMeButton.attr('data-notify-me-inventory-status', invStatus);
      $product.attr('data-sku-inv-status', invStatus);
    } else {
      return false;
    }
    // Hide all other statuses and show the right one.
    if ($newStatus.length > 0 && $addBtn.length > 0) {
      $statusList.show();
      $statusList.parent().show();
      $newStatus.show();
      if ((invStatus === 7 && skuDataL2.MISC_FLAG === 139) || invStatus !== 7) {
        $invStatusNotifyMe.removeClass('hidden');
      }
      if (!Drupal.settings.globals_variables.cr21_mpp) {
        $.fn.matchHeight._update();
      }
    }
    // Handle disabling of add to cart button here on inv status.
    if (skuDataL2 && !skuDataL2.isShoppable) {
      if ($product.hasClass('product-full--enhanced')) {
        if (!isMobile) {
          if (!$notifyMeButton.is(':visible')) {
            $liveChatContainer.addClass('fullwidth');
          }
        }
      }
      $addBtn.hide().addClass('hidden');
      if (isCr21) {
        $addBtnContainer.addClass('hidden');
      }
    } else {
      $liveChatContainer.removeClass('fullwidth');
      $addBtn.show().removeClass('hidden');
      if (isCr21) {
        $addBtnContainer.removeClass('hidden');
      }
    }

    if (
      skuDataL2 &&
      (skuDataL2.MISC_FLAG === 15 || skuDataL2.isPreOrder) &&
      skuDataL2.RELEASE_DATE
    ) {
      $addBtn.html(site.translations.product.pre_order_btn);
      setPreOrderMessage(skuDataL2, $product);
      $statusList.show();
      $statusList.parent().show();
      $('.js-product-inventory-status--preorder', $statusList).show();
    } else {
      $addBtn.html(site.translations.product.add_to_bag);
    }
  });

  function setPreOrderMessage(skuData, $product) {
    var preOrderDates = getPreOrderDates(skuData.RELEASE_DATE);

    if (preOrderDates['releaseDate'] && preOrderDates['shipDate']) {
      var $sppContext = $('.product-full__inventory-status', $product);
      var $mppContext = $('.product-brief__inventory-status', $product);

      if (skuData.isShoppable) {
        $('.js-product-inventory-status--preorder', $sppContext).html(
          getMessage('sppprod_preorder_ships_by_desc', preOrderDates['shipDate'])
        );
        $('.js-product-inventory-status--preorder', $mppContext).html(
          getMessage('mpp_preorder_shoppable_msg', preOrderDates['shipDate'])
        );
      } else {
        var nonShoppableMessage = getMessage(
          'mpp_preorder_non_shoppable_msg',
          preOrderDates['releaseDate']
        );

        $('.js-product-inventory-status--preorder', $sppContext).html(nonShoppableMessage);
        $('.js-product-inventory-status--preorder', $mppContext).html(nonShoppableMessage);
      }
    }
  }

  function getMessage(translationKey, dateStr) {
    var message = site.translations.product[translationKey] || '';

    if (message) {
      message = message.replace(/::release_date::/, dateStr);
    }

    return message;
  }

  function getPreOrderDates(releaseDateStr) {
    var dateFormatMatched = releaseDateStr.match(/\d{2}/g);
    var preOrderDates = {
      releaseDate: null,
      shipDate: null
    };

    if (dateFormatMatched) {
      var releaseDate = new Date(
        '20' + dateFormatMatched[0] + '-' + dateFormatMatched[1] + '-' + dateFormatMatched[2]
      );
      var shipDays = parseInt(site.translations.product.ship_days || '1');
      var shipDate = new Date(releaseDate);

      shipDate.setDate(shipDate.getDate() + shipDays);
      var preorder_date_format = Drupal.settings.globals_variables.preorder_date_format || '';
      var getDateStr = function (d) {
        var formatted_date = '';
        var yyyy = d.getUTCFullYear(),
          mm = ('0' + (d.getUTCMonth() + 1)).slice(-2),
          dd = ('0' + d.getUTCDate()).slice(-2);

        switch (preorder_date_format) {
          case 'YYYY/MM/DD':
            formatted_date = yyyy + '/' + mm + '/' + dd;
            break;
          case 'DD/MM':
            formatted_date = dd + '/' + mm;
            break;
          case 'MM/DD':
            formatted_date = mm + '/' + dd;
            break;
          default:
            formatted_date = d.getUTCMonth() + 1;
            formatted_date += '/';
            formatted_date += d.getUTCDate();
            break;
        }

        return formatted_date;
      };

      preOrderDates['releaseDate'] = getDateStr(releaseDate);
      preOrderDates['shipDate'] = getDateStr(shipDate);
    }

    return preOrderDates;
  }

  $(document).on('product.skuSelect', '.js-product-ui:not(.js-product-bundle-ui)', function () {
    var $product = $(this);
    var $statusList = $('.js-product-inventory-status-list', $product);

    if ($statusList.length < 1) {
      // If current product does not have an inv status list
      // we are in a partial product, return early.
      return;
    }

    $('.product-inventory-status__item', $statusList).hide();
    // Trigger inventory status event.
    $product.trigger('product.updateInvStatus');
  });
})(jQuery);
